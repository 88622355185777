.contacts{
    position: relative;

}
.contacts .ymaps-2-1-79-map{
    filter: grayscale(1);
    border-radius: 14px;
    overflow: hidden;
}
.contacts__address{
    color: white;
    font-family: "Montserrat", sans-serif;
}
.contacts__alert{
    color: white;
    font-family: "Montserrat", sans-serif;
}
.content__text-box{
    color: white;
    font-family: "Montserrat", sans-serif;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 30px;
}
.content__text-flex{
    display: flex;
    width: 45%;
    justify-content: space-between;
}
.content__requisites{
    font-size: 19px;
    margin-left: 30px;
}

.content__text p:nth-child(1){
    font-size: 60px;
    margin: 20px 0;
    font-weight: bolder;
}
.content__text p:nth-child(2){
    font-size: 30px;
    font-weight: bold;
}
.content__icons{
    display: flex;
    width: 265px;
    justify-content: space-between;
    align-self: flex-end;
}
.content__icons a{
    display: block;
    width: 45px;
    height: 45px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: .15s;
    color: white;
}
.content__icons a:hover{
    transform: scale(1.08);
}

.inactive_link{
    transform: none !important;
    filter: grayscale(1);
    pointer-events: none;
}


.content__icons a:nth-child(1){
    background-image: url("./img/inst_ico.svg");
    position: relative;
}

.content__icons a:nth-child(1):after{
    display: block;
    content: "*";
    font-family: "Montserrat", sans-serif;
    font-size: 26px;
    right: -9px;
    top: -9px;
    position: absolute;
}

.content__icons a:nth-child(3){
    background-image: url("./img/vk_ico.svg");
}
.content__icons a:nth-child(2){
    background-image: url("./img/th_ico.svg");
}
.contacts__address{
    font-size: 25px;
}
.contacts__alert{
    margin: 15px 0 30px 0;
}
@media screen and (max-width: 800px){
    .contacts .content__text-box{
        align-items: flex-start;
    }
    .content__text-box{
        flex-direction: column;
    }
    .content__text p:nth-child(1){
        font-size: 35px;
    }
    .content__text p:nth-child(2){
        font-size: 20px;
    }
    .content__text-flex{
        width: 100%;
        margin-top: 40px;
    }
    .content__icons{
        flex-direction: column;
        width: auto;
        height: 150px;
    }
    .content__requisites{
        font-weight: bolder;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .contacts__address{
        font-size: 20px;
    }
    .map{
        margin: auto;
    }
}