.about{
    position: relative;
}
.about__section-about__paragraph-list{
    font-family: "Montserrat", sans-serif;
    margin-top: 70px;
    margin-bottom: 20px;
}

@media screen and (max-width: 800px) {
    .pageTitle__text_media{
        padding-left: 20px;
        padding-right: 20px;
    }
    .about .banner__text-field{
        width: 100%;
    }
    .about .banner__text-field p{
        font-size: 16px;
        line-height: normal;
    }
    .about .pageTitle__text{
        width: 100%;
        font-size: 16px;
        line-height: normal;
    }
    .about .personal{
        flex-direction: column;
        align-items: center;
    }
    .personal__card{
        width: 300px !important;
        margin-bottom: 50px;
    }
    .personal__card:last-child{
        margin-bottom: 0;
    }
    .about .cardServices{
        width: 370px;
    }
    .about .cardServices img{
        width: 90%;
    }
    .about .content{
        padding: 0;
    }
    .simpleFeedback{
        flex-direction: column;
        margin-top: 20px;
        height: auto!important;
        margin-bottom: 50px;
    }
    .simpleFeedback__text-box{
        width: 95%!important;
    }
    .simpleFeedback__text-box h2{
        font-size: 35px!important;
        line-height: normal!important;
    }
    .simpleFeedback__feedback-form{
        width: 100%!important;
    }
    .simpleFeedback__text-box p{
        margin-bottom: 30px;
    }
    .media_delay{
        display: block;
        width: 100%;
        height: 80px;
    }
}