.pageTitle{
    width: 100%;
    font-family: "Montserrat", sans-serif;
    color: white;
    font-weight: bolder;
    font-size: 30px;
    padding-top: 70px;
}
.pageTitle__title-box{
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.pageTitle__title{
    white-space: nowrap;
}

.pageTitle__line{
    display: block;
    width: 100%;
    height: 4px;
    background-color: #00cfc7;
    margin-left: 20px;
}

.pageTitle__text{
    font-size: 20px;
    font-weight: lighter;
    line-height: 30px;
    width: 80%;
}

.top-margin{
    padding-top: 150px;
}

.bottom-margin{
    margin-bottom: 40px;
}
@media screen and (max-width: 800px){
    .pageTitle{
        padding-left: 20px;
        padding-right: 20px;
    }
}