.portfolio{
    position: relative;
}
.portfolio__content{
    width: 1400px;
    margin: auto;
    padding-bottom: 70px;
}
.portfolio__card-box{
    display: grid;
    gap: 30px 60px;
    grid-template-columns: repeat(auto-fill, 670px);
}
@media screen and (max-width: 800px){
    .CardPortfolio {
        width: 380px!important;
        height: 590px!important;
    }
    .CardPortfolio img{
        width: 340px!important;
        margin-top: 20px;
    }
    .portfolio__content{
        width: 100%!important;
    }
    .portfolio__card-box{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
