.services__card-box{
    width: 100%;
    display: grid;
    gap: 30px 35px ;
    grid-template-columns: repeat(auto-fill, minmax(430px, 1fr));
}
.services{
    position: relative;
}

@media screen and (max-width: 800px){
    .services .content{
        padding: 0 10px;
    }
    .services__card-box{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .calc{
        flex-direction: column;
    }
    .calc__text-filed{
        width: 100% !important;
        max-width: 644px;
    }
    .pageTitle__title{
        white-space: pre-wrap;
    }
    .calc__text-filed h2{
        margin-top: 20px!important;
        width: 100% !important;
        line-height: normal!important;
        font-size: 28px !important;
        text-align: center;
    }
    .calc__text-filed p{
        text-align: center;
        width: 100% !important;
    }
    .calc__calc-filed{
        width: 100%!important;
        flex-direction: column;
        height: auto!important;
        padding: 20px 20px!important;
    }
    .calc__text-filed{
        align-self: center;
        height: auto!important;
        padding-bottom: 60px;
    }
    .calc__calc-filed__left-filed, .calc__calc-filed__right-filed{
        width: 100%!important;
    }
    .calc__calc-filed__text-group p{
        font-size: 15px!important;
    }
    .calc__calc-filed__button-group button:first-child{
        width: 100%!important;
    }
    .calc h4{
        font-size: 13px!important;
    }
    .calc input{
        font-size: 100%!important;
    }
    .calc:after{
        display: none!important;
    }
}