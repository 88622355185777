header{
    top:0;
    height: 100px;
    display: flex;
    user-select: none;
    position: fixed;
    z-index: 5;
    width: 100%;
    transition: .2s;
}

header .content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
}

.header-navigate{
    width: 550px;
    display: flex;
    justify-content: space-between;
}

.header-navigate > .header-navigate__link {
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    color: white;
    font-weight: 600;
    transition: .2s;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6px;
}

.header-navigate > .header-navigate__link:hover{
    color: #a8a8a8;
}




.logo{
    display: block;
    height: 70px;
    width: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url("/public/img/logo.svg");
}

.header-navigate__link::after{
    display: block;
    content: "";
    width: 0;
    height: 4px;
    border-radius: 2px;
    background-color: #00cfc7;
    transition: .2s;
    margin-top: 2px;
}

.header-navigate__link_active::after{
    width: 100%;
}

.header_opacity-true{
    background-color: #222222;
}

.header_opacity-false{
    background-color: rgba(34, 34, 34, 0.13);
    backdrop-filter: blur(3px);
}

.offcanvas{
    background-color: rgba(50, 50, 50, 0.54);
    backdrop-filter: blur(10px);
    color: white;
    font-family: "Montserrat", sans-serif;
}
.offcanvas-title{
    font-size: 40px;
    font-weight: bolder;
}
.header-navigate_offcanvas{
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 25px;

}
.header-navigate_offcanvas a{
    color: white;
    text-decoration: none;
}

.offcanvas-body{
    position: relative;
}

.offcanvas-body .download_link{
    position: absolute;
    bottom: 30px;
    font-family: "Montserrat", sans-serif;
}

.list{
    display: none;
    width: 50px;
    height: 50px;
    background-image: url("./img/list.svg");
    color: white;
    background-size: contain;
    filter: invert(1);
}

@media screen and (max-width: 800px){
    header .logo{
        width: 140px;
        height: 60px;
        background-position: left;
    }

    .list{
        display: block;
    }

    header .content {
        width: 100%;
    }
}