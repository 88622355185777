.background-box{
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    z-index: -1;
    height: 100%;
    overflow: hidden;
}

.background{
    height: 100%;
    width: 1500px;
    position: relative;
    margin: auto;
    top: 0;
    left: auto;
    right: auto;
}

.background__image-first, .background__image-second{
    width: 100%;
    padding: 0;
    margin: 0;
}

.background__pipe-left, .background__pipe-right{
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    background-position: top;
    background-repeat: repeat-y;
}

.background__pipe-left{
    left: 140px;
    width: 300px;
    background-image: url("./img/pipeLeft.png");
}

.background__pipe-right{
    right: -70px;
    width: 85px;
    background-image: url("./img/pipeRight.png");
}

@media screen and (max-width: 800px){
    .background__pipe-right{
        display: none;
    }
    .background__pipe-left{
        left: 15%;
    }
}