.modal{
    font-family: "Montserrat", sans-serif;
    color: white;

}
.modal-content{
    background-color: #323232;

}

.modal-header{
    border-color: #00cfc7;
    padding-right: 40px;
    padding-left: 40px;
}
.btn-close{
    filter: invert(1);
}
.modal img{
    width: 90%;
}
.modal .splide__slide {
    display: flex;
    justify-content: center;
}
.modal-body{

}
.modal-footer{
    display: flex;
    justify-content: center;
    border-color: #00cfc7;
}
.modal-footer button{
    height: 60px;
    border-radius: 8px;
    border: none;
    font-size: 1.1rem;
    width: 60%;
    font-family: "Montserrat", sans-serif;
    font-weight: bolder;
    color: white;
    background-color: #20b2aa;
    align-self: flex-end;
}

.modal-footer button:hover{
    color: #cecece;
    background-color: #1b9690;
    border-color: #1b9690;
}
.modal .pageTitle{
    padding-top: 0;
    margin-bottom: 35px;
    padding-right: 10px;
    padding-left: 10px;
}
.modal  .pageTitle__line{
    height: 1px;
}
.modal  .pageTitle__title-box{
    margin-bottom: 30px;
}
.modal .pageTitle__text{
    font-size: 18px;
    width: 100%;
    line-height: 25px;
}
.modal .simpleFeedback__feedback-form{
    width: 90%;
    background-color: #464646;
}

.modal input::-webkit-outer-spin-button,
.modal input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.modal input:focus-visible{
    outline: 1px solid #00cfc7;
}
.modal__coast{
    width: 100%;
    padding-right: 30px;
    text-align: end;
    font-size: 25px;
    color: #00D1C9;
    font-weight: bolder;
    margin-bottom: 20px;
}
