.calc{
    width: 100%;
    display: flex;
    padding-bottom: 70px;
    position: relative;
}
.calc:after{
    display: block;
    content: '';
    width: 350px;
    height: 870px;
    background-image: url("./img/calc_pipe.png");
    position: absolute;
    z-index: -1;
    right: 30px;
    top: -640px;
}

.calc__text-filed{
    width: 570px;
    height: 644px;
}
.calc__calc-filed{
    height: 644px;
    width: 830px;
    display: flex;
    justify-content: space-between;
    background-color: #3C3C3C;
    border-radius: 15px;
    padding: 38px 48px;
}
.calc__calc-filed__left-filed{
    width: 330px;
}
.calc__calc-filed__right-filed{
    width: 330px;
}
.calc__text-filed{
    color: white;
    font-family: "Montserrat", sans-serif;
    position: relative;
}
.calc__text-filed h2{
    font-weight: bolder;
    line-height: 70px;
    font-size: 50px;
    margin: 70px 0 40px 0;
}

.calc__text-filed p{
    width: 90%;
    line-height: 25px;
}
.calc__text-filed__arrow{
    display: block;
    position: absolute;
    width: 340px;
    height: 110px;
    background-image: url("./img/arrow.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    right: 30px;
    bottom: 60px;
}

.calc__calc-filed__input-group{

}
.calc__calc-filed__input-group h4{
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    margin: 18px 0;
    color: white;
    font-size: 15px;
}
.calc__calc-filed__input-group input, .calc__calc-filed__input-group select{
    height: 60px;
    border-radius: 8px;
    border: none;
    background-color: #323232;
    box-shadow: none;
    color: white;
    font-family: "Montserrat", sans-serif;
    padding: 0 20px;
    font-size: 1.1rem;
}

.calc__calc-filed__input-group select{
    appearance: none;
}

.calc__calc-filed__input-group select:focus-visible{
    outline: 1px solid #00cfc7;
}
.calc__calc-filed__input-group_date-box{
    display: flex;
    justify-content: space-between;
}
.calc__calc-filed__input-group_date-box input{
    width: 32%;
}

.input_w100{
    width: 100%;
}
.calc__calc-filed__input-group input:focus-visible{
    outline: 1px solid #00cfc7;
}

.calc__calc-filed__text-group p{
    font-family: "Montserrat", sans-serif;
    color: white;
    font-size: 17px;
    margin: 19px 0;
}

.calc__calc-filed__button-group{
    display: flex;
    justify-content: space-between;
}

.calc__calc-filed__button-group button{
    height: 60px;
    border-radius: 8px;
    border: none;
    font-size: 1.1rem;
    font-family: "Montserrat", sans-serif;
    font-weight: bolder;
    color: white;
    background-color: #00D1C9;
}
.calc__calc-filed__button-group button:first-child{
    width: 80%;
}

.calc__calc-filed__button-group :nth-child(2){
    color: white;
    background-color: #00D1C9;
    content: none;
    height: 60px;
    width: 60px;
    background-image: url("./img/button-icon.svg");
    background-size: 45px;
    background-position: center;
    background-repeat: no-repeat;
    font-size: 1.1rem;
    border-radius: 8px;
    border: none;
    margin-left: 5px;
}

.file-label{
    overflow: hidden;
}

.calc input::-webkit-outer-spin-button,
.calc input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media screen and (max-width: 1317px){
    .calc{
        flex-direction: column;
    }
    .calc__text-filed{
        align-self: center;
        height: auto;
        margin-bottom: 320px;
    }
    .calc__calc-filed{
        align-self: center;
    }
    .calc__text-filed__arrow{
        transform: rotate(308deg) scale(-1, 1);
        bottom: -220px;
    }
    .calc__calc-filed{
        max-width: 830px;
        width: 100%;
    }
}

@media screen and (max-width: 800px){
    .calc__text-filed__arrow{
        bottom: -180px;
    }
    .calc__text-filed {
        margin-bottom: 280px;
    }
}