.simpleFeedback{
    height: 470px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.simpleFeedback__text-box{
    width: 60%;

}
.simpleFeedback__feedback-form{
     width: 540px;
     height: 330px;
     display: flex;
     flex-direction: column;
     background-color: #3C3C3C;
     border-radius: 15px;
     padding: 25px;
     justify-content: space-between;
}
.simpleFeedback__feedback-form input{
     height: 60px;
     border-radius: 8px;
     border: none;
     background-color: #323232;
     box-shadow: none;
     color: white;
     font-family: "Montserrat", sans-serif;
     padding: 0 20px;
     font-size: 1.1rem;
}

.simpleFeedback__feedback-form button{
     height: 60px;
     border-radius: 8px;
     border: none;
     font-size: 1.1rem;
     font-family: "Montserrat", sans-serif;
     font-weight: bolder;
     color: white;
     background-color: #00D1C9;
}
.simpleFeedback__feedback-form p{
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
}

.simpleFeedback input:focus-visible{
    outline: 1px solid #00cfc7;
}

.simpleFeedback input::-webkit-outer-spin-button,
.simpleFeedback input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.simpleFeedback__text-box h2{
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 70px;
    font-weight: bolder;
    line-height: 70px;
    margin-bottom: 30px;
}

.simpleFeedback__text-box p{
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
}