.banner{
    width: 100%;
    height: 600px;
    position: relative;
    margin-top: 40px;
}
.banner__img{
    width: 100%;
    height: 100%;
    align-self: center;
    object-fit: cover;
    border-radius: 20px;
}
.banner__text-field{
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    font-family: "Montserrat", sans-serif;
    padding: 30px ;
    width: 70%;
    border-radius: 0 8px 0 0;
    backdrop-filter: blur(10px);
    overflow: hidden;
}

.banner__text-field h2{
    font-size: 30px;
    margin-bottom: 30px;
    font-weight: bolder;
}

.banner__text-field p{
    font-size: 20px;
    line-height: 30px;
}

.banner__icon{
    display: block;
    background-image: url("./img/banner-icon.svg");
    width: 100%;
    height: 30px;
    margin-top: 20px;
    background-position: right;
    background-repeat: no-repeat;
}