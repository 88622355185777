.notFound{
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 100;
    display: flex;
    justify-content: center;align-items: center;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
}
.notFound__background{
    width: 100vw;
    height: 100vh;
    display: block;
    position: absolute;
    background-image: url("/public/img/banner_img.png");
    z-index: 98;
    background-position: center;
    background-size: cover;
}

.notFound p{

    font-weight: bold;
    font-size: 100px;
    z-index: 99;
}
.notFound a{
    margin-top: 20px;
    font-size: 20px;
    color: #323232;
    z-index: 99;
}