.cardServices{
    width: 100%;
    background-color: #3C3C3C;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    position: relative;
    color: white;
    font-family: "Montserrat", sans-serif;
    transition: .15s;
}
.cardServices:hover{
    transform: scale(1.04);
    box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.3);
}

.splide .cardServices:hover{
    transform: none;
    box-shadow:none;
    background-color: #2f2f2f;
}

.cardServices__image-box{
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
}

.cardServices__image{
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 15px;
    align-self: center;
    margin-top: 20px;
    max-width: 100%;
}

.cardServices__icon{
    display: block;
    width: 120px;
    height: 29px;
    background-image: url("./img/icon_details.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: 38px;
    top: 35px;
}
.cardServices__title{
    font-size: 20px;
    font-weight: bolder;
}

.cardServices__description{
    height: 120px;
    overflow: hidden;
    line-height: 20px;
    display: flex;
    padding-top: 20px;
}

.cardServices__coast{
    align-self: flex-end;
    font-size: 25px;
    color: #00D1C9;
    font-weight: bolder;
}

.cardServices__text-box{
    height: 245px;
    padding: 20px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
@media screen and (max-width: 800px){
    .modal .simpleFeedback__feedback-form{
        width: 100%!important;
    }
}

