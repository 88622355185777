
.section-banner{
    margin-top: 100px;
    position: relative;
    box-shadow: inset 0 -90px 260px 100px #04565f, 0px 5px 50px black;
}

.section-banner__banner{
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.section-banner__content{
    color: white;
}

.section-banner__content h1{
    font-family: "Montserrat", sans-serif;
    font-size: 4rem;
    font-weight: 800;
    user-select: none;
}

.section-banner__content ul{
    margin-top: 3rem;
    user-select: none;
}

.section-banner__content li{
    font-family: "Montserrat", sans-serif;
    font-size: 1.5rem;
    list-style-type: none;
    margin-top: 10px;
}

.section-banner__btn{
    margin-top: 3rem;
    width: 100%;
    max-width: 350px;
    height: 70px;
    font-family: "Montserrat", sans-serif;
    font-size: 1.8rem;
    border-radius: 8px;
    background-color: #272727;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    color: white;
    text-decoration: none;
    font-weight: 600;
}

.section-banner__btn:hover{
    transition: .15s;
    transform: scale(0.99);
}

.section-banner__pipe-banner{
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url(/public/img/pipe_banner.png);
    background-position: center;
    background-size: cover;
}

.section-banner__video-banner{
    z-index: -2;
    position: relative;
    min-height: 100%;
    min-width: 100%;
    filter: hue-rotate(315deg);
}

.section-banner__mobile-top-img{
    display: none;
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url(/public/img/mobile-banner-img.jpg);
    background-position: center;
    background-size: cover;
}

.section-banner__mobile-bottom-img{
    display: none;
    z-index: 2;
    position: absolute;
    min-height: 40%;
    bottom: 0;
    min-width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(/public/img/troobs.png);
}


.section-banner__content{
    height: 960px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.section-banner__paragraph-list{
    display: flex;
    justify-content: center;
    gap: 4rem;
    font-family: "Montserrat", sans-serif;
}
.section-banner__paragraph-list h5{
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.section-banner__paragraph-list p{
    font-size: 1rem;
    font-weight: 300;
    line-height: 30px;
}

.section-about{
    background-image: url(/public/img/sec2_back.png);
    background-color: #222222;
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
    font-family: "Montserrat", sans-serif;
}

.section-about__title{
    height: 420px;
    display: flex;
    justify-content: space-between;
    color: white;
}

.section-about__title >div{
    width: 850px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section-about__title h3{
    font-weight: 800;
    font-size: 2.15rem;
    user-select: none;
}

.section-about__title h2{
    margin-top: 2.5rem;
    font-size: 1.2rem;
    line-height: 25px;
}

.section-about__2th-title{
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 2.5rem;
    color: white;
    margin-bottom: 50px;
    user-select: none;
}
.section-coasts .splide__pagination{
    bottom: -25px;
}
.section-about__pipe{
    display: block;
    background-image: url(/public/img/pipe_short.png);
    width: 400px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.section-about__paragraph-list{
    display: flex;
    gap: 10px;
    margin-top: -25px;
    margin-bottom: 7rem;
}

.section-about__paragraph-list h3{
    margin-bottom: 15px;
    font-size: 1.2rem;
    font-weight: 600;
    color: #1bdcd2;
    line-height: 25px;
}

.section-about__paragraph-list p{
    font-size: .9rem;
    font-weight: 300;
    color: white;
    line-height: 25px;
}

.section-about__paragraph-list > div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    border-radius: 8px;
    background-color: #353535;
    box-sizing: border-box;
    transition: .18s;
    user-select: none;

}
.section-about__paragraph-list > div:hover{
    transform: scale(1.1);
    box-shadow: #101010 0px 0px 40px 0px ;
}

.section-coasts{
    background-image: url(/public/img/sec3_back.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 80px 0;
}
.services-slider {
    width: 100%;
}
.card{
    width: 440px;
    height: 650px;
    background-color: #272727;
    border-radius: 10px;
    padding: 40px 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: "Montserrat", sans-serif;
    box-sizing: border-box;
}

.card > p{
    color: white;
    display: flex;
}

.card__round{
    display: block;
    width: 15px;
    height: 15px;
    background-color: #00cfc7;
    border-radius: 10px;
    flex-shrink: 0;
    margin-right: 10px;
}
.card__substrate{
    height: 70px;
    display: flex;
    align-items: center;
    padding-left: 28px;
    border-radius: 10px;
}

.card__title{
    background-color: #009792;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
}

.card__coast{
    font-size: 1.2rem;
    background-color: white;
    font-weight: 800;
}

.card__btn{
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 1.2rem;
}
.card__arrow{
    display: block;
    width: 60px;
    height: 30px;
    background-image: url(/public/img/arrow.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    margin-right: 10px;
}

.card_large{
    height: 770px;
}

.section-coasts{
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-coasts > .content{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.section-coasts .splide__slide{
    display: flex;
    justify-content: center;
}
.section-coasts .splide{
    padding: 0;
}

.pipe-long{
    display: block;
    width: 510px;
    height: 2100px;
    background-image: url(/public/img/pipe_long.png);
}
.mobile-pipe-long{
    display: none;
    height: 537px;
    position: relative;
}
.section-calc{
    background-color: #222222;
    background-image: url(/public/img/sec4_back.png);
    background-position: center;
    background-size: 1370px;
    background-repeat: no-repeat;
}

.section-calc > .content {
    position: relative;
    display: flex;
    justify-content: space-between;
}
.section-calc__content{
    width: 850px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.section-calc__title{
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 2.15rem;
    user-select: none;
    color: white;
}

.section-calc__grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
}
.section-calc__card{
    display: flex;
    color: white;
    font-family: "Montserrat", sans-serif;
}
.section-calc__card__round{
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #00cfc7;
    color: black;
    font-weight: 600;
    margin-right: 25px;
}

.section-calc__card__content h4{
    font-size: 1.1rem;
    font-weight: 800;
    margin-bottom: 30px;
}

.section-calc__card__content h5{
    line-height: 25px;
}

.form{
    width: 500px;
    height: 850px;
    display: flex;
    flex-direction: column;
}
.form__title{
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 2.15rem;
    user-select: none;
    color: white;
}
.form__description{
    margin: 50px 0;
    color: white;
    font-family: "Montserrat", sans-serif;
    line-height: 25px;
}

.form_input-text{
    height: 60px;
    border-radius: 8px;
    margin-bottom: 20px;
    border: none;
    background-color: #323232;
    box-shadow: none;
    color: white;
    font-family: "Montserrat", sans-serif;
    padding: 0 20px;
    font-size: 1.1rem;
}

.form_input-text:focus-visible{
    outline: 1px solid #00cfc7;
}

.form__policy-adoption{
    display: flex;
    align-items: flex-start;
}
.form__policy-adoption > p {
    color: white;
    margin-left: 10px;
    font-family: "Montserrat", sans-serif;
    line-height: 15px;
    font-size: .8rem;
    margin-bottom: 25px;
}

form button{
    border: none;
    box-shadow: none;
    height: 90px;
    border-radius: 8px;
    background-color: #00cfc7;
    color: white;
    font-weight: 600;
    font-size: 1.2rem;
    font-family: "Montserrat", sans-serif;
}

.pipe-valve{
    width: 470px;
    height: 470px;
    display: block;
    position: absolute;
    background-image: url(/public/img/pipe_valve.png);
    bottom: 330px;
    right: 180px;
}
.mobile-pipe-valve{
    width: 170px;
    height: 170px;
    display: block;
    position: absolute;
    background-size: contain;
    background-image: url(/public/img/pipe_valve.png);
    bottom: 205px;
    right: 200px;
}
.pipe-valve:hover{
    transition: 1s;
    transform: rotate(360deg);
}

@media screen and (max-width: 1200px) {
    .section-about__paragraph-list {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(430px, 1fr));
    }
}

@media screen and (max-width: 1150px) {
    .section-calc__title{
        padding-top: 50px;
        margin-bottom: 50px;
    }
    .pipe-long {
        align-self: end;
    }
    .section-calc__content {
        padding-bottom: 50px;
    }
    .pipe-valve {
        right: 120px;
    }
}

@media screen and (max-width: 1080px) {
    .section-banner__paragraph-list {
        display: none;
    }
    .section-calc__content {
         width: 100%;
    }
    .section-calc__grid {
        grid-template-columns: 1fr;
    }
    .pipe-long {
        display: none;
    }
    .pipe-valve {
        display: none;
    }
    .section-calc__grid {
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 800px) {
    /*.content{*/
    /*    width: 390px;*/
    /*}*/
    .header-navigate{
        display: none !important;
    }
    header{
        height: 80px;
    }
    header .logo{
        height: 50px;
    }
    .section-banner__content{
        width: 100%;
        justify-content: center;
        padding-top: 0;
        height: 100vh;
    }
    .section-banner {
        margin-top: 0;
    }
    .section-banner__content h1{
        font-size: 32px;
        line-height: 40px;
    }
    .section-banner__content li{
        font-size: 15px;
    }
    .section-banner__paragraph-list{
        display: none;
    }
    .section-about__pipe{
        display: none;
    }
    .section-about{
        height: 100%;
    }
    .section-about__paragraph-list{
        display: grid;
    }
    .home .splide{
        width: 100%;
    }
    .home .cardServices img{
        width: 90%;
    }
    .section-calc__title{
        margin: 50px 0;
        width: 360px;
        line-height: 50px;
    }
    .section-calc__grid{
        grid-template-columns: 1fr;
    }
    .section-calc__card{
        width: 90%;
    }
    .pipe-valve{
        display: none;
    }
    .section-calc{
        height: auto;
    }
    .form{
        width: 350px;
        margin-bottom: 50px;
        height: auto;
    }
    .form__title{
        margin: 50px 0;
        width: 360px;
        line-height: 50px;
    }
    .form__description{
        margin: 20px 0;
    }
    .form button{
        height: 60px;
    }

    .cardServices__text-box{
        height: auto !important;
    }
}
@media screen and (max-height: 650px) {
    .section-banner__content {
        height: 650px;
    }
}
@media screen and (max-width: 500px) {
    .section-banner__video-banner {
        display: none;
    }
    .section-banner__pipe-banner{
        display: none;
    }
    .section-banner__mobile-top-img{
        display: block;
    }
    .section-banner__mobile-bottom-img{
        display: block;
    }
    .section-banner {
        box-shadow: none;
    }
    .mobile-pipe-long{
        display: flex;
        justify-content: center;
    }
    .section-about__paragraph-list {
        margin-bottom: 0;
    }
    .section-about__paragraph-list {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
    }
}
@media screen and (max-width: 470px) {
    .mobile-pipe-valve {
        right: 185px;
    }
}
@media screen and (max-width: 445px) {
    .mobile-pipe-valve {
        right: 175px;
    }
}
@media screen and (max-width: 425px) {
    .mobile-pipe-valve {
        right: 163px;
    }
}
@media screen and (max-width: 400px) {
    .form {
        width: 100%;
    }
    .mobile-pipe-valve {
        right: 152px;
    }
    .section-calc__title {
        width: auto;
    }
    .form__title {
        width: auto;
    }
}
@media screen and (max-width: 380px) {
    .mobile-pipe-valve {
        right: 143px;
    }
}
@media screen and (max-width: 360px) {
    .mobile-pipe-valve {
        right: 130px;
    }
}
@media screen and (max-width: 337px) {
    .mobile-pipe-valve {
        right: 120px;
    }
}