.CardPortfolio{
    width: 670px;
    height: 540px;
    background-color: #3C3C3C;
    position: relative;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    color: white;
    font-family: "Montserrat", sans-serif;
    transition: .15s;
}

.CardPortfolio:hover{
    transform: scale(1.04);
    box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.3);
}

.CardPortfolio__image{
    width: 610px;
    height: 300px;
    object-fit: cover;
    border-radius: 15px;
    align-self: center;
    margin-top: 25px;
}
.CardPortfolio__icon{
    display: block;
    width: 120px;
    height: 30px;
    background-image: url("./img/icon_details.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: 38px;
    bottom: 30px;
}
.CardPortfolio__title{
    font-size: 20px;
    font-weight: bolder;
}
.CardPortfolio__description{
    height: 80px;
    overflow: hidden;
    line-height: 25px;
}
.CardPortfolio__coast{
    font-size: 25px;
    color: #00D1C9;
    font-weight: bolder;
}

.CardPortfolio__text-box{
    height: 200px;
    padding: 10px 35px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}