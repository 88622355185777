footer{
    height: 210px;
    width: 100%;
    background-color: #181818;
    font-family: "Montserrat", sans-serif;
    padding: 40px 0;
    box-sizing: border-box;
    color: white;

}

footer > .content{
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.logo_black{
    background-image: url(/public/img/logo_black.svg);
    display: block;
    width: 250px;
    height: 90px;
    background-position: center;
    background-size: 105%;
    background-repeat: no-repeat;
}

.footer__logo-box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.footer__content-box{
    width: 950px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer__content-box nav{
    margin-top: 2.2rem;
    display: flex;
    justify-content: space-between;
}

.footer__content-box__link, .download_link{
    font-weight: 600;
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: .2s;
    position: relative;
}

.footer__content-box__link:after{
    display: block;
    content: "";
    width: 0;
    height: 4px;
    border-radius: 2px;
    background-color: #ffffff;
    transition: .2s;
    margin-top: 2px;
}

.footer__content-box___link_active:after{
    width: 100%;
}

.download_link{
    position: relative;
    display: block;
}

.download_link:after {
    position: absolute;
    display: block;
    content: "";
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: #ffffff;
    transition: .2s;
    margin-top: 2px;
    bottom: 0;
}

.download_link:hover:after{
    animation: .6s download_link_animate ease-in-out;
}

@keyframes download_link_animate {
    0%{
        right: 0;
        width: 100%;
    }
    50%{
        right: 0;
        width: 0;
    }
    100%{
        left: 0;
        width: 100%;
    }
}


.footer__content-box nav a:hover{
    color: #a8a8a8;
}

.footer__contact-list{
    display: flex;
    justify-content: space-between;
    gap: 100px;
    font-size: .9rem;
}

.footer__contact-list >div :first-child{
    font-weight: 600;
}

.footer__contact-list >div:last-child p{
    font-weight: 300;
}
.footer__address{
    width: 230px;
}

@media screen and (max-width: 1280px){
    footer > .content {
        flex-direction: column;
    }
    .footer__content-box{
        width: 100%;
    }
    .footer__content-box nav {
        margin-bottom: 15px;
    }
    footer{
        height: auto;
    }
}

@media screen and (max-width: 1000px){
    footer > .content {
        align-items: center;
    }
    .footer__content-box nav {
        flex-direction: column;
    }
    .footer__content-box___link_active:after {
        height: 2px;
        bottom: -2px;
        margin-bottom: 3px;
    }
    .footer__content-box__link, .download_link {
        width: fit-content;
        align-self: center;
    }
    .footer__contact-list{
        flex-direction: column;
        gap: 10px;
        align-self: center;
        text-align: center;
    }
    .footer__rights-reserved{
        text-align: center;
        margin-top: 10px;
    }
}