.unable-gas-alert{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: 100%;
    z-index: 2;
    top: 0;
    padding: 10px;
    font-size: 18px;
    margin-top: 100px;
    font-family: "Montserrat", sans-serif;
}

.unable-gas-alert span{
    color: black;
    font-weight: 500;
}